$max-size-xs: 380px;
$max-size-s: 719px;
$max-size-m: 1024px;
$max-size-l: 1540px;
$max-size-xl: 1920px;


/******************************
  MIXINS
*******************************/

// xs = smartphone (display width max 550px)
// s = phablet (display width max 719px)
// m = tablet in portrait orientation (display width bigger than smartphone, max 939px)
// l = tablet in landscape orientation (display width larger than 939px, max 1024px)
// xl = desktop (display width larger than 1024px)
@mixin respond-to($media) {
  @if $media == xs {
    @media only screen and (max-width: $max-size-xs) {
      @content;
    }
  } @else if $media == s {
    @media only screen and (min-width: $max-size-xs + 1) and (max-width: $max-size-s) {
      @content;
    }
  } @else if $media == m {
    //@media only screen and (orientation: portrait) and (min-device-width: $tablet-portrait) and (max-device-width: $tablet-landscape) { @content; }
    @media only screen and (min-width: $max-size-s + 1) and (max-width: $max-size-m) {
      @content;
    }
  } @else if $media == l {
    //@media only screen and (orientation: landscape) and (min-device-width: $tablet-portrait) and (max-device-width: $tablet-landscape) { @content; }
    @media only screen and (min-width: $max-size-m + 1) and (max-width: $max-size-l) {
      @content;
    }
  } @else if $media == xl {
    @media only screen and (min-width: $max-size-l + 1) {
      @content;
    }
  } @else if $media == xs-s {
    @media only screen and (max-width: $max-size-s) {
      @content;
    }
  } @else if $media == xs-s-m {
    @media only screen and (max-width: $max-size-m) {
      @content;
    }
  } @else if $media == xs-s-m-l {
    @media only screen and (max-width: $max-size-l) {
      @content;
    }
  } @else if $media == s-m {
    @media only screen and (min-width: $max-size-xs + 1) and (max-width: $max-size-m) {
      @content;
    }
  } @else if $media == m-l {
    @media only screen and (min-width: $max-size-s + 1) and (max-width: $max-size-l) {
      @content;
    }
  } @else if $media == s-m-l {
    @media only screen and (min-width: $max-size-xs + 1) and (max-width: $max-size-l) {
      @content;
    }
  } @else if $media == s-m-l-xl {
    @media only screen and (min-width: $max-size-xs + 1) {
      @content;
    }
  } @else if $media == m-l-xl {
    @media only screen and (min-width: $max-size-s + 1) {
      @content;
    }
  } @else if $media == l-xl {
    @media only screen and (min-width: $max-size-m + 1) {
      @content;
    }
  }
}

/******************************
  GENERAL SETUP AND CLASSES
*******************************/
.show-for-xs {
  display: none;
  @include respond-to(xs) {
    display: block;
    display: initial;
  }
}

.show-for-s {
  display: none;
  @include respond-to(s) {
    display: block;
    display: initial;
  }
}

.show-for-m {
  display: none;
  @include respond-to(m) {
    display: block;
    display: initial;
  }
}

.show-for-l {
  display: none;
  @include respond-to(l) {
    display: block;
    display: initial;
  }
}

.show-for-xl {
  display: none;
  @include respond-to(xl) {
    display: block;
    display: initial;
  }
}

.show-for-xs-s {
  display: none;
  @include respond-to(xs-s) {
    display: block;
    display: initial;
  }
}

.show-for-xs-s-m {
  display: none;
  @include respond-to(xs-s-m) {
    display: block;
  }
}

.show-for-xs-s-m-l {
  display: none;
  @include respond-to(xs-s-m-l) {
    display: block;
    display: initial;
  }
}

.show-for-s-m {
  display: none;
  @include respond-to(s-m) {
    display: block;
    display: initial;
  }
}

.show-for-s-m-l {
  display: none;
  @include respond-to(s-m-l) {
    display: block;
    display: initial;
  }
}

.show-for-m-l {
  display: none;
  @include respond-to(m-l) {
    display: block;
    display: initial;
  }
}

.show-for-s-m-l-xl {
  display: none;
  @include respond-to(s-m-l-xl) {
    display: block;
    display: initial;
  }
}

.show-for-m-l-xl {
  display: none;
  @include respond-to(m-l-xl) {
    display: block;
    display: initial;
  }
}

.show-for-l-xl {
  display: none;

  @include respond-to(l-xl) {
    display: block;
    display: initial;
  }
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.no-scroll {
  overflow: hidden;
}

.full-width {
  width: 100%;
}

.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}