@import "font-faces";

@mixin NittiNormal(){
  font-family: 'nitti-grotesk-normal', HelveticaNeue-Light, HelveticaNeue, sans-serif;
  font-weight: normal;
}

@mixin NittiBold(){
  font-family: 'nitti-grotesk-bold', HelveticaNeue-Light, HelveticaNeue, sans-serif;
  font-weight: normal;
}

@mixin NittiBlack(){
  font-family: 'nitti-grotesk-black', HelveticaNeue-Light, HelveticaNeue, sans-serif;
  font-weight: normal;
  font-style: normal;
}