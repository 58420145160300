/******************************
  Lib
*******************************/

@import "lib/normalize";
@import "lib/breakpoints";
@import "lib/grid";

/******************************
  Mixins
*******************************/

@import "mixins/pseudo";
@import "mixins/userselect";
@import "mixins/text";
@import "mixins/background";

/******************************
  Var
*******************************/

@import "var/colors";
@import "var/easings";
@import "var/fonts";
@import "var/font-faces";

/******************************
  Base
*******************************/

@import "base/clearfix";

/******************************
  Modules
*******************************/

@import "modules/_video";
@import "modules/_intro";
@import "modules/_image";
@import "modules/_page";

/******************************
  Partials
*******************************/

@import 'partials/_header';
@import 'partials/_footer';

#site-container {
  width: 100%;
  background-color: #f7f7f7;
}

#site-wrapper {
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

@media only screen and (min-width: 1600px) {
  #site-wrapper {
    width: 85%
  }
}
