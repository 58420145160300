.intro-module {
  .page-content {
    height: 100%;
    .row {
      margin: 0 auto;
      height: 100%;
      .intro-body {
        text-align: center;
        @include NittiNormal;
        font-size: 30px;
      }
      .intro-image-wrapper {
        margin-top: -180px;
        margin-bottom: 100px;
        width: 100%;
        height: auto;
        .intro-image {
          width: 100%;
          height: auto;
          margin: 0 auto;
        }
      }
      .column {
        .intro-headline {
          text-align: center;
          margin: 50px 0 0 0;
          @include NittiBlack;
          font-size: 72px;
        }
      }
    }
  }
}


@media only screen and (min-width: 300px) and (max-width: 600px) {
  .intro-module {
    .page-content {
      .row {
        .column {
          .intro-headline {
            text-align: center;
            margin: -50px 0 0 0;
            font-size: 48px;
            line-height: 45px;
          }
          .intro-body {
            text-align: center;
            @include NittiNormal;
            font-size: 23px;
            line-height: 25px;
          }
        }
      }
    }
  }
}