$font-path: "/assets/fonts/";

@font-face {
  $file-name: 'nittigrotesk-normal-v20';
  font-family: 'nitti-grotesk-normal';
  src: url($font-path + $file-name + '.eot');
  src: url($font-path + $file-name + '.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($font-path + $file-name + '.woff') format('woff'), /* Moderne Browser */
}

@font-face {
  $file-name: 'nittigrotesk-bold-v20';
  font-family: 'nitti-grotesk-bold';
  src: url($font-path + $file-name + '.eot');
  src: url($font-path + $file-name + '.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($font-path + $file-name + '.woff') format('woff'), /* Moderne Browser */
}

@font-face {
  $file-name: 'nittigrotesk-black-v20';
  font-family: 'nitti-grotesk-black';
  src: url($font-path + $file-name + '.eot');
  src: url($font-path + $file-name + '.eot?#iefix') format('embedded-opentype'),
  url($font-path + $file-name + '.woff') format('woff'),
}
