.header-content {
  margin-bottom: 200px;
  .shifted {
    height: 450px;
    background-color: #ff0064;

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      height: 100px;
      top: 400px;
      left: 0;
      transform: skewY(-2deg);
      z-index: 1;
      background: #ff0064;
    }

    #logo {
      width: 50%;
      margin: 0 auto;
      padding-top: 70px;
      .logo {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
  .header-content {
    .shifted {
      height: 250px;

      #logo {
        width: 60%;
        margin: 0 auto;
        padding-top: 70px;
        .logo {
          width: 100%;
        }
      }

      &::after {
        top: 230px;
        height: 50px;
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 650px) {
  .header-content {
    .shifted {
      height: 300px;

      #logo {
        width: 60%;
        margin: 0 auto;
        padding-top: 70px;
        .logo {
          width: 100%;
        }
      }

      &::after {
        top: 280px;
        height: 80px;
      }
    }
  }
}

@media only screen and (min-width: 650px) and (max-width: 900px) {
  .header-content {
    .shifted {
      height: 400px;

      &::after {
        top: 350px;
        height: 75px;
      }
    }
  }
}

@media only screen and (min-width: 1240px) and (max-width: 1700px) {
  .header-content {
    .shifted {
      height: 600px;

      &::after {
        top: 550px;
        height: 100px;
      }
    }
  }
}

@media only screen and (min-width: 1700px) and (max-width: 1920px) {
  .header-content {
    .shifted {
      height: 700px;

      &::after {
        top: 650px;
        height: 100px;
      }
    }
  }
}

@media only screen and (min-width: 1920px) and (max-width: 10000px) {
  .header-content {
    .shifted {
      height: 800px;

      &::after {
        top: 750px;
        height: 100px;
      }
    }
  }
}