@mixin background(){
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin background-cover(){
  @include background();
  background-size: cover;
}

@mixin background-contain(){
  @include background();
  background-size: contain;
}