.footer-content {
  margin-top: 50px;

  .shifted {
    height: 500px;
    background-color: #ff0064;
    position: relative;

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 450px;
      left: 0;
      transform: skewY(-2deg);
      z-index: 1;
      background: #ff0064;
    }

    #footer-logo {
      width: 50%;
      margin: 0 auto;
      padding-top: 60px;
      .logo {
        width: 100%;
      }
    }

    .footer {
      padding-top: 50px;
      text-align: center;
      .footer-text {
        color: white;
        max-width: 80%;
        margin: 0 auto;
        span {
          display: block;
          @include NittiNormal;
          font-size: 20px;
          line-height: 24px;
          a {
            text-decoration: none;
            color: white;
          }
        }
      }
    }
  }

  .shifted-container {
    margin-bottom: 60px;
    display: block;
    .shifted-footer {
      transform: skewY(-2deg);
      width: 90%;
      margin-top: -10px;
      .shifted-social {
        background-color: #ff0064;
        position: relative;
        display: inline-block;
        width: 80%;
        .inner {
          color: white;
          text-align: right;
          padding: 8px;
          .text {
            @include NittiBlack;
          }
        }
      }
      .shifted-logo {
        display: inline;
        .twitter-logo {
         height: 60px;
          position: relative;
          top: 20px;
        }
        .facebook-logo {
          height: 40px;
          position: relative;
          top: 10px;
          left: 10px
        }
      }
      &.short {
        width: 80%;
      }
    }
  }
}

a {
  text-decoration: none;
}

@media only screen and (min-width: 300px) and (max-width: 460px) {
  .footer-content {
    margin-top: 100px;

    .shifted {
      height: 300px;
      background-color: #ff0064;
      position: relative;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 40px;
        bottom: 280px;
        left: 0;
        transform: skewY(-2deg);
        z-index: 1;
        background: #ff0064;
      }
    }

    .shifted-container {
      margin-bottom: 30px;
      display: block;
      .shifted-footer {
        transform: skewY(-2deg);
        width: 95%;
        margin-top: -10px;
        .shifted-social {
          background-color: #ff0064;
          position: relative;
          display: inline-block;
          width: 80%;
          .inner {
            color: white;
            text-align: right;
            padding: 8px;
            .text {
              @include NittiBlack;
            }
          }
        }
        .shifted-logo {
          display: inline;
          .twitter-logo {
            height: 45px;
            position: relative;
            top: 15px;
          }
          .facebook-logo {
            height: 40px;
            position: relative;
            top: 10px;
            left: 10px
          }
        }
        &.short {
          width: 85%;
        }
      }
    }

  }
}

@media only screen and (min-width: 450px) and (max-width: 720px) {
  .footer-content {
    margin-top: 100px;

    .shifted {
      height: 400px;
      background-color: #ff0064;
      position: relative;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 350px;
        left: 0;
        transform: skewY(-2deg);
        z-index: 1;
        background: #ff0064;
      }
    }
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1920px) {
  .footer-content {
    margin-top: 100px;

    .shifted {
      height: 600px;
      background-color: #ff0064;
      position: relative;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 550px;
        left: 0;
        transform: skewY(-2deg);
        z-index: 1;
        background: #ff0064;
      }
    }
  }
}

@media only screen and (min-width: 1920px) and (max-width: 10000px) {
  .footer-content {
    margin-top: 100px;

    .shifted {
      height: 600px;
      background-color: #ff0064;
      position: relative;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 550px;
        left: 0;
        transform: skewY(-2deg);
        z-index: 1;
        background: #ff0064;
      }
    }
  }
}