/******************************
  PROPS
*******************************/
$grid-columns: 12;
$grid-full-width: 100%;
$grid-element-width: $grid-full-width / $grid-columns;
$mobile-padding: $grid-full-width / ($grid-columns*2);

$max-page-width: 1920px;

$grid-element-spacing-xl: 12px;
$grid-element-spacing-l: 12px;
$grid-element-spacing-m: 12px;
$grid-element-spacing-s: 12px;
$grid-element-spacing-xs: 12px;

$grid-sizes: "xs", "s", "m", "l", "xl";

@mixin page-content() {

  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-left: $grid-element-spacing-s;
  padding-right: $grid-element-spacing-s;

}


@mixin width-limited(){
  max-width: $max-page-width;
}

@mixin no-padding() {
  padding-left: 0;
  padding-right: 0;
}

.page-content {

 @include page-content();

  &.width-limited {
    @include width-limited();
  }

  &.no-padding {
    @include no-padding();
  }

}

//@include respond-to(xs-s-m-l-xl){
  .page-content {
    padding-left: 0;
    padding-right: 0;
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
//}


/******************************
  MIXINS
*******************************/

@mixin make-grid($size, $spacing) {
  @for $i from 1 through $grid-columns {
    /* MAIN GRID */
    @include getGridElements($i, $size, $grid-columns, $spacing);
  }

  @include getGridElements(0.5, $size, $grid-columns, $spacing, "half");

  .grid-container {
    padding: 0 ($spacing * 0.5);
  }

  .row {
    margin-left: -($spacing * 0.5);
    margin-right: -($spacing * 0.5);
  }

  .column {
    padding: 0 ($spacing * 0.5);
  }

  .grid-spacing-box {
    padding: $spacing * 0.5;
  }
}

// set default size (s, m, l or xl...)
@mixin getGridElements($id, $size, $cols, $spacing, $name:"") {

  @if $name == "" {
    $name: $id;
  }

  .size-#{$size}-#{$name} {
    width: ($id) * ($grid-full-width / $cols);
    $smaller: true;
    @each $grid-size in $grid-sizes {
      @if ($grid-size == $size) {
        $smaller: false;
      }
      @if ( $smaller) {
        &.size-#{$grid-size}-plus-a-half {
          width: ($id) * ($grid-full-width / $cols);
        }
      }
    }
    &.size-#{$size}-plus-a-half {
      width: $id * ($grid-full-width / $cols) + $grid-full-width / ($cols * 2);
    }
    &.no-padding {
      @include no-padding();
    }
  }

  @if $name == $id {
    $name: $id - 1;
  }

  @if( $name == "half" ) {
    .offset-#{$size}-by-#{$name} {
      margin-left: ($id) * ($grid-full-width / $cols);
    }
  } @else {
    .offset-#{$size}-by-#{$name} {
      margin-left: ($id - 1) * ($grid-full-width / $cols);
      $smaller: true;
      @each $grid-size in $grid-sizes {
        @if ($grid-size == $size) {
          $smaller: false;
        }
        @if ( $smaller) {
          &.offset-#{$grid-size}-plus-a-half {
            margin-left: ($id - 1) * ($grid-full-width / $cols);
          }
        }
      }
      &.offset-#{$size}-plus-a-half {
        margin-left: ($id - 1) * ($grid-full-width / $cols) + $grid-full-width / ( $cols * 2 );
      }
    }
  }

}

/******************************
  GRID DEFAULTS
*******************************/
*, *:before, *:after {
  box-sizing: border-box;
}

.row {
  clear: both;
  position: relative;
  &:after {
    clear: both;
    content: " ";
    display: table;
  }

  &:before {
    content: " ";
    display: table;
  }

  > * {
    float: left;
  }

  &.no-spacing {
    margin: 0 !important;
  }
}

.column {
  position: relative;
  min-height: 1px;

  &.no-spacing {
    padding: 0 !important;
  }

  &.no-margin {
    margin: 0 !important;
  }
}

/******************************
  CREATE DEFAULT GRID SIZE
*******************************/
@include make-grid(xs, $grid-element-spacing-s);

/******************************
  MEDIAQUERIES
*******************************/
// grid size "s"
@media only screen and (min-width: $max-size-xs + 1) {
  @include make-grid(s, $grid-element-spacing-s);
}

// grid size "m"
@media only screen and (min-width: $max-size-s + 1) {
  @include make-grid(m, $grid-element-spacing-m);
}

// grid size "l"
@media only screen and (min-width: $max-size-m + 1) {
  @include make-grid(l, $grid-element-spacing-l);
}

// grid size "xl"
@media only screen and (min-width: $max-size-l + 1) {
  @include make-grid(xl, $grid-element-spacing-xl);
}
