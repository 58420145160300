.video-module {
  .page-content {
    .column {
      padding: 0;
      .shifted {
        position: relative;
        top: 60px;
        transform: skewY(-2deg);
        background-color: white;
        height: 100%;
        max-width: 90%;
        display: inline-block;
        .shifted-reverse {
          padding-top: 20px;
          transform: skewY(2deg);
        }
        .video-headline {
          @include NittiBlack;
          font-size: 6.5vw;
          line-height: 5.5vw;
          text-transform: uppercase;
          margin-bottom: 35px;
          margin-left: 40px;
          margin-right: 40px;
          word-break: normal;
        }
      }
      .vid-container {
        video {
          width: 100%;
          z-index: 1;
          object-fit: inherit;
        }
        &::after {
          content: ' ';
          position: relative;
          bottom: 45px;
          display: block;
          width: 100%;
          height: 90px;
          background-color: white;
          z-index: 1000;
          transform: skewY(-2deg);
        }
      }
      .video-controls {
        position: absolute;
        right: 0;
        bottom: 70px;
        z-index: 1000;;
        .playpause {
          padding: 0;
          margin: 0;
          border: none;
          cursor: pointer;
          background: transparent;
          background-size: contain;
          background-repeat: no-repeat;
          outline: none;
          .playpause-button {
            width: auto;
            height: 150px;
          }
        }
      }
      .video-copy {
        @include NittiNormal;
        font-size: 25px;
        margin: -50px 0 0 0;
        padding: 20px 0 0 0;
      }
      ul {
        padding: 20px 0 0 0;
        margin: -50px 0 0 0;
        font-size: 25px;
        li {
          text-decoration: none;
          list-style: none;
          @include NittiNormal;
          span {
            @include NittiBold;
          }
        }
      }
    }
    .column-z {
      z-index: 1000;
    }

  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .video-module {
    margin-bottom: -30px;
    .page-content {
      .column {
        .shifted {
          .video-headline {
            @include NittiBlack;
            font-size: 9.5vw;
            line-height: 8.5vw;
          }
        }
        .vid-container {
          &::after {
            bottom: 20px;
          }
        }
        .video-controls {
          .playpause {
            .playpause-button {
              width: auto;
              height: 110px;
            }
          }
        }
        .video-copy {
          @include NittiNormal;
          font-size: 25px;
          margin: -10px 0 0 0;
          padding: 0;
        }
        ul {
          margin: 0;
          font-size: 20px;
          li {
            text-decoration: none;
            list-style: none;
            @include NittiNormal;
            span {
              @include NittiBold;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .video-module {
    margin-bottom: -30px;
    .page-content {
      .column {
        .shifted {
          position: relative;
          top: 50px;
          display: block;
          width: 90%;
          margin: 0 auto;
          .video-headline {
            @include NittiBlack;
            font-size: 9.5vw;
            line-height: 9.5vw;
            text-align: center;
          }
        }
        .vid-container {
          &::after {
            bottom: 20px;
          }
        }
        .video-controls {
          .playpause {
            bottom: 60px;
            .playpause-button {
              width: auto;
              height: 95px;
            }
          }
        }
        .video-copy {
          @include NittiNormal;
          font-size: 25px;
          margin: -10px 0 0 0;
          padding: 0;
        }
        ul {
          margin: 0;
          font-size: 20px;
          li {
            text-decoration: none;
            list-style: none;
            @include NittiNormal;
            span {
              @include NittiBold;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .video-module {
    margin-bottom: -30px;
    .page-content {
      .column {
        .shifted {
          position: relative;
          top: 30px;
          display: block;
          width: 90%;
          margin: 0 auto;
          .shifted-reverse {
            padding-left: 0;
            padding-right: 0;
          }
          .video-headline {
            @include NittiBlack;
            font-size: 9vw;
            line-height: 8vw;
            text-align: center;
            margin-left: 15px;
            margin-right: 15px;
          }
        }
        .vid-container {
          &::after {
            bottom: 20px;
          }
        }
        .video-controls {
          bottom: 50px;
          .playpause {
            .playpause-button {
              width: auto;
              height: 85px;
            }
          }
        }
        .video-copy {
          @include NittiNormal;
          font-size: 23px;
          line-height: 24px;
          margin: -15px 0 0 0;
          padding: 0;
        }
        ul {
          padding: 20px 0 0 0;
          margin: 0;
          font-size: 19px;
          line-height: 20px;
          li {
            text-decoration: none;
            list-style: none;
            @include NittiNormal;
            span {
              @include NittiBold;
            }
          }
        }
      }
    }
  }
}


