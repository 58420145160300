.image-module {
  .page-content {
    .column {
      padding: 0;
      .shifted {
        position: relative;
        top: 60px;
        transform: skewY(-2deg);
        background-color: white;
        height: 100%;
        max-width: 90%;
        display: inline-block;
        .shifted-reverse {
          padding-top: 35px;
          transform: skewY(2deg);
        }
        .image-headline {
          @include NittiBlack;
          font-size: 6.5vw;
          line-height: 5.5vw;
          text-transform: uppercase;
          margin-left: 40px;
          margin-right: 40px;
        }
      }
      .image-container {
        img {
          width: 100%;
          z-index: 1;
          object-fit: inherit;
        }
        &::after {
          content: ' ';
          position: relative;
          bottom: 25px;
          display: block;
          width: 100%;
          height: 60px;
          background-color: white;
          z-index: 1000;
          transform: skewY(-2deg);
        }
      }
      .image-copy {
        @include NittiNormal;
        font-size: 25px;
        margin: 25px 0 0 0;
        padding: 0;
      }
      ul {
        padding: 20px 0 0 0;
        margin: 0;
        font-size: 25px;
        li {
          text-decoration: none;
          list-style: none;
          @include NittiNormal;
          span {
            @include NittiBold;
          }
        }
      }
    }
    .column-z {
      z-index: 1000;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .image-module {
    .page-content {
      height: 100%;
      .column {
        padding: 0;
        .shifted {
          position: relative;
          top: 60px;
          .image-headline {
            @include NittiBlack;
            font-size: 9vw;
            line-height: 8.5vw;
          }
        }
        .image-container {
          &::after {
            bottom: 25px;
          }
        }
        ul {
          padding: 20px 0 0 0;
          margin: 0;
          font-size: 25px;
          li {
            text-decoration: none;
            list-style: none;
            @include NittiNormal;
            span {
              @include NittiBold;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .image-module {
    margin-bottom: -50px;
    .page-content {
      height: 100%;
      .column {
        padding: 0;
        .shifted {
          position: relative;
          top: 50px;
          display: block;
          width: 90%;
          margin: 0 auto;
          .image-headline {
            @include NittiBlack;
            font-size: 9.5vw;
            line-height: 9.5vw;
            text-align: center;
          }
        }
        .image-copy {
          @include NittiNormal;
          font-size: 25px;
          margin: 20px 0 0 0;
          padding: 0;
        }
        ul {
          padding: 20px 0 0 0;
          margin: 0;
          font-size: 20px;
          li {
            text-decoration: none;
            list-style: none;
            @include NittiNormal;
            span {
              @include NittiBold;
            }
          }
        }
        .image-container {
          &::after {
            bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .image-module {
    margin-bottom: -50px;
    .page-content {
      height: 100%;
      .column {
        padding: 0;
        .shifted {
          position: relative;
          top: 30px;
          display: block;
          width: 90%;
          margin: 0 auto;
          .shifted-reverse {
            padding-left: 0;
            padding-right: 0;
          }
          .image-headline {
            font-size: 9vw;
            line-height: 8vw;
            text-align: center;
            margin-left: 15px;
            margin-right: 15px;
          }
        }
        .image-copy {
          @include NittiNormal;
          font-size: 23px;
          line-height: 24px;
          margin: 20px 0 0 0;
          padding: 0;
        }
        ul {
          padding: 19px 0 0 0;
          margin: 0;
          font-size: 19px;
          line-height: 20px;
          li {
            text-decoration: none;
            list-style: none;
            @include NittiNormal;
            span {
              @include NittiBold;
            }
          }
        }
        .image-container {
          &::after {
            bottom: 10px;
          }
        }
      }
    }
  }
}